import { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { useGLTF, Html } from '@react-three/drei';
import { applyProps, useThree, useFrame } from '@react-three/fiber'
import * as THREE from "three";
import { gsap } from "gsap";

function Marker({ children, ...props }) {
  const ref = useRef()
  // This holds the local occluded state
  const [isOccluded, setOccluded] = useState()
  const [isInRange, setInRange] = useState()
  const isVisible = isInRange && !isOccluded
  // Test distance
  const vec = new THREE.Vector3()
  useFrame((state) => {
    const range = state.camera.position.distanceTo(ref.current.getWorldPosition(vec)) <= 10
    if (range !== isInRange) setInRange(range)
  })
  return (
    <group ref={ref}>
      <Html
        // 3D-transform contents
        transform
        // Hide contents "behind" other meshes
        occlude
        // Tells us when contents are occluded (or not)
        onOcclude={setOccluded}
        // We just interpolate the visible state into css opacity and transforms
        style={{ transition: 'all 0.2s',  }}
        {...props}>
        {children}
      </Html>
    </group>
  )
}

function Car(props) {
  const {
    carId,
    modelFile,
    suspension,
    color,
    isShowOemSpoiler,
    selectedPaint,
    isFocusSpolier,
    onClickSpoiler,
    onLookAtSpoiler
  } = props;
  const { gl, camera, mouse } = useThree();
  const { scene, nodes, materials } = useGLTF(modelFile, '/draco/gltf/')
  const [initBodyNodeY, setInitBodyNodeY] = useState({});

  const raycaster = useMemo(() => new THREE.Raycaster(), []);

  useEffect(() => {
    nodes['_body'].children.forEach((ch) => {
      if (ch.isMesh) {
        applyProps(ch, { castShadow: true });
      }
    })

    if (isShowOemSpoiler) {
      applyProps(nodes['oem_spoiler'], { visible: true });
      applyProps(nodes['limo_spoiler'], { visible: false });
    } else {
      applyProps(nodes['oem_spoiler'], { visible: false });
      applyProps(nodes['limo_spoiler'], { visible: true });
    }

    applyProps(nodes['tyre_va_l'], { receiveShadow: true });
    applyProps(nodes['tyre_va_r'], { receiveShadow: true });
    applyProps(nodes['tyre_ha_l'], { receiveShadow: true });
    applyProps(nodes['tyre_ha_r'], { receiveShadow: true });

    // Object.keys(materials).forEach((matkey) => {
    //   if (matkey.includes('Mat_tyres-uv')) {
    //     applyProps(materials[matkey], { envMapIntensity: 0.5 })
    //   }
    // })

    Object.keys(materials).forEach((matkey) => {
      if (matkey.includes('Mat_tyres')) {
        applyProps(materials[matkey], { envMapIntensity: 0.5 })
      }
      if (matkey.includes('Mat_bottom')) {
        applyProps(materials[matkey], { envMapIntensity: 0, roughness: 1 })
      }
    })

    applyProps(materials["Mat_front-glass"], { ior: 1.1, iridescenceIOR: 1.2, specularIntensity: 1.8, clearcoat: 0.35 })
    if (materials['Mat_rear-lights']) {
      applyProps(materials['Mat_rear-lights'], { emissiveIntensity: 0.25, color:  new THREE.Color('#FF1A05').convertLinearToSRGB() })
    }
    // applyProps(materials['Mat_car_glossy'], { color:  new THREE.Color(color).convertLinearToSRGB() })
    if (!initBodyNodeY[carId]) {
      setInitBodyNodeY({
        ...initBodyNodeY,
        [carId]: nodes['_body'].clone().position.y
      })
    } else {
      applyProps(nodes['_body'].position, { y: initBodyNodeY[carId] })
    }
  }, [scene, materials, color]);

  useEffect(() => {
    if (initBodyNodeY[carId]) {
      gsap.to(nodes['_body'].position, { y: initBodyNodeY[carId] + suspension })
    }
  }, [suspension])

  useEffect(() => {
    // 0 = Gloss
    // 1 = Matte
    if (selectedPaint === 0) {
      applyProps(materials['Mat_car_glossy'], { roughness: 0.20000000298023224, clearcoatRoughness: 0.029999999329447746 })
    } else {
      applyProps(materials['Mat_car_glossy'], { roughness: 0.35, clearcoatRoughness: 0.35 })
    }
  }, [selectedPaint, materials])

  const getMouseUvPosition = useCallback(() => {
    raycaster.setFromCamera( mouse, camera );
    const intersects = raycaster.intersectObjects([
      nodes['oem_spoiler'],
      nodes['limo_spoiler'],
    ]);
    if (intersects.length < 0) {
      return [];
    }
    return intersects;
  }, [mouse, camera, nodes, raycaster]);

  const onDocumentMouseDown = useCallback((e) => {
    e.preventDefault();
    const mouseUvPos = getMouseUvPosition();
    if (mouseUvPos.length === 0) {
      return;
    }
    onClickSpoiler(mouseUvPos[0].object.parent);
  }, [getMouseUvPosition]);

  useEffect(() => {
    if (isShowOemSpoiler) {
      applyProps(nodes['oem_spoiler'], { visible: true });
      applyProps(nodes['limo_spoiler'], { visible: false });
      if (isFocusSpolier) {
        onLookAtSpoiler(nodes['oem_spoiler']);
      } else {
        onClickSpoiler(nodes['oem_spoiler']);
      }
    } else {
      applyProps(nodes['oem_spoiler'], { visible: false });
      applyProps(nodes['limo_spoiler'], { visible: true });
      if (isFocusSpolier) {
        onLookAtSpoiler(nodes['limo_spoiler']);
      } else {
        onClickSpoiler(nodes['limo_spoiler']);
      }
    }
  }, [isShowOemSpoiler, nodes]);


  useEffect(() => {
    const mouseCursorContainer = document.querySelector('.Configurator');
    if (!mouseCursorContainer) {
      return;
    }
    mouseCursorContainer.addEventListener('mousedown', onDocumentMouseDown);
    return () => {
      mouseCursorContainer.removeEventListener('mousedown', onDocumentMouseDown);
    };
  }, [onDocumentMouseDown]);

  return (
    <group>
      <primitive object={scene} />
      {/* <Marker rotation={[0, -Math.PI / 2, 0]} position={[1.997015118598938, 1.034416913986206, -0.07075636833906174]}>
        <p style={{ fontSize: '5px' }}>Spoiler</p>
      </Marker> */}
      <directionalLight
        position={[0, -0.5, 0]}
        intensity={0.5}
        castShadow={false}
      />
      <directionalLight
        position={[0, 2, 0]}
        intensity={0.1}
        castShadow={true}
      />
    </group>
  );
}

export default Car;
