import { Suspense, useState, useMemo, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom';
import { Canvas } from '@react-three/fiber'
import {
  PerspectiveCamera,
  Environment,
  useProgress,
  CameraControls
} from '@react-three/drei';
import { debounce } from 'lodash';

import { useParams, useNavigate } from 'react-router-dom';
import { useControls } from 'leva'

import { ChromePicker } from 'react-color'

import jflogo from '../assets/jflogo.png';

import IconSpray from '../components/icons/IconSpray';
import IconSuspension from '../components/icons/IconSuspension';
import IconChangeCar from '../components/icons/IconChangeCar';
import IconGallery from '../components/icons/IconGallery';

import Car from '../components/Car';
import Background from '../components/Background';
import ClickOutside from '../components/ClickOutside';

import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

const HDRI_LIST = {
  'canary_wharf': require('../assets/hdri/canary_wharf_2k.hdr'),
  'canary_wharf_4k': require('../assets/hdri/canary_wharf_4k.hdr'),
};

const BG_LIST= [
  {
    key: 'empty',
  },
  {
    key: 'canary',
    bg: require('../assets/bg/canary_BG_04.glb'),
    img: require('../assets/bg/canary_BG_04.webp'),
    hdri: require('../assets/hdri/canary_wharf_4k.hdr'),
    ground: {
      height: 5.5, // Height of the camera that was used to create the env map (Default: 15)
      radius: 55, // Radius of the world. (Default 60)
      scale: 100, // Scale of the backside projected sphere that holds the env texture (Default: 1000)
    }
  },
  {
    key: 'el_playazo',
    bg: require('../assets/bg/el-playazo_BG_03.glb'),
    img: require('../assets/bg/el-playazo_BG_03.webp'),
    hdri: require('../assets/hdri/el_playazo_4K.hdr'),
    ground: {
      height: 4.5, // Height of the camera that was used to create the env map (Default: 15)
      radius: 65, // Radius of the world. (Default 60)
      scale: 120, // Scale of the backside projected sphere that holds the env texture (Default: 1000)
    }
  },
  {
    key: 'freight_station',
    bg: require('../assets/bg/freight_station_BG_01.glb'),
    img: require('../assets/bg/freight_station_BG_01.webp'),
    hdri: require('../assets/hdri/freight_station_2k.hdr'),
    ground: {
      height: 0.5, // Height of the camera that was used to create the env map (Default: 15)
      radius: 150, // Radius of the world. (Default 60)
      scale: 1000, // Scale of the backside projected sphere that holds the env texture (Default: 1000)
    }
  },
]

const CAR_OPTIONS_LIST = [
  {
    key: 'audi-rs-3',
    img: require('../assets/audi-rs-3.webp'),
    file: require('../assets/cars/oem/11642_Audi_003_spoiler.glb'),
    name: 'Audi RS 3 Sedan'
  },
  // {
  //   key: 'tesla',
  //   img: require('../assets/model-3.webp'),
  //   file: require('../assets/cars/oem/10456_Tesla_001_AO.glb'),
  //   name: 'Tesla Model 3'
  // },
  // {
  //   key: 'skoda',
  //   img: require('../assets/skoda-karoq.jpg'),
  //   file: require('../assets/cars/oem/11685_Skoda_001_AO.glb'),
  //   name: 'Skoda Karoq Facelift'
  // },
]

function Configurator() {
  const camRef = useRef();
  const canvasRef = useRef();
  const cameraControlsRef = useRef(null);
  const navigate = useNavigate();
  const { car: carRoute } = useParams();
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [selectedCarIndex, setSelectedCarIndex] = useState(0);
  const [selectedBgIndex, setSelectedBgIndex] = useState(0);
  const [selectedPaint, setSelectedPaint] = useState(0);
  const [showDelayLoading, setShowDelayLoading] = useState(false);

  const [showCarSuspension, setShowCarSuspension] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showCarInfoTab, setShowCarInfoTab] = useState(false);
  const [showCarList, setShowCarList] = useState(false);
  const [showBgList, setShowBgList] = useState(false);
  const [showBg, setShowBg] = useState(false);
  const [showCarInfoTabTiming, setShowCarInfoTabTiming] = useState(null);

  const [carSuspension, setCarSuspension] = useState(0);
  const [carColor, setCarColor] = useState('#410000');

  const [isShowOemSpoiler, setIsShowOemSpoiler] = useState(false);
  const [isFocusSpolier, setIsFocusSpolier] = useState(false);
  const [initializedCamera, setInitializedCamera] = useState(false);

  const startDistance = useRef(0);
  const distanceCount = useRef(0);
  const endDistance = useRef(0);

  const { progress, loaded } = useProgress();

  const selectedCarRouteIndex = useMemo(() => CAR_OPTIONS_LIST.findIndex(item => item.key === carRoute), [carRoute]);
  const isMobileAndTabletControlVertical = useMemo(() => windowSize.innerWidth < 800 && window.matchMedia('(orientation: portrait)').matches, [windowSize.innerWidth]);
  const isMobileAndTabletHorizontal = useMemo(() => windowSize.innerWidth < 900 && window.matchMedia('(orientation: landscape)').matches, [windowSize.innerWidth]);

  function PaintOption() {
    return <ul className="flex w-full mt-4">
      <li
        className={
          "rounded-l grow border p-1 text-center font-serpentine text-[12px] cursor-pointer" +
          (selectedPaint === 0 ? ' text-white bg-[#535760] border-[#535760]' : ' text-[#535760] bg-white border-[#dadada]')
        }
        onClick={() => setSelectedPaint(0)}
      >
        GLOSS
      </li>
      <li
        className={
          "rounded-r grow border border-l-0 border-[#dadada] p-1 text-center font-serpentine text-[12px] cursor-pointer" +
          (selectedPaint === 1 ? ' text-white bg-[#535760] border-[#535760]' : ' text-[#535760] bg-white border-[#dadada]')
        }
        onClick={() => setSelectedPaint(1)}
      >
        MATTE
      </li>
    </ul>
  }
  useEffect(() => {
    setSelectedCarIndex(selectedCarRouteIndex)
  }, [selectedCarRouteIndex]);


  function Loader() {
    return (
      <img src={jflogo} alt="jflogo" className="animate-flicker" />
    )
  }
  function LoadingScreen() {
    return (
      <div className="loading-overlay screen show">
        <Loader />
      </div>
    );
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      const vh = window.innerHeight;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (progress === 100 && loaded > 0) {
      setShowDelayLoading(true)
      setShowDelayLoadingWithDelay({
        status: false,
        delay: 5000
      });
    } else {
      setShowDelayLoading(true)
    }
  }, [progress, loaded])

  // useEffect(() => {
  //   console.log('cameraControlsRef.current', cameraControlsRef.current)
  //   if (cameraControlsRef.current) {
  //     cameraControlsRef.current.addEventListener('control', function(e) {
  //       if (distanceCount.current >= 6) {
  //         distanceCount.current = 0;
  //         startDistance.current = e.target.distance;
  //       } else {
  //         distanceCount.current += 1;
  //       }
  //       handleOnZoom(e.target.distance);
  //     })
  //   }
  // }, [cameraControlsRef])

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  function setShowDelayLoadingWithDelay({ status, delay }) {
    if (!delay) {
      setShowDelayLoading(status)
      return;
    }
    setTimeout(() => {
      setShowDelayLoading(status)
      if (!status) {
        handleOrbitEnd()
      }
    }, delay)
  }

  function handleColorChange(e) {
    setCarColor(e.hex)
  }
  function handleSuspensionChange(val) {
    setCarSuspension(val)
  }
  function handleToggleSuspension(toggle) {
    if (toggle === 'add' && carSuspension < 0) {
      setCarSuspension(
        parseFloat(
          ((carSuspension * 100 + 1) / 100).toFixed(2)
        )
      )
    } else if (toggle === 'minus' && carSuspension > -0.08) {
      setCarSuspension(
        parseFloat(
          ((carSuspension * 100 - 1) / 100).toFixed(2)
        )
      )
    }
  }
  function handleSelectCar(car) {
    navigate('/' + car.key)
    setTimeout(() => {
      setShowCarList(false);
      setCarSuspension(0)
    }, 200)
  }
  function handleSelectBg(index) {
    setSelectedBgIndex(index);
    setShowBgList(false);
    if (index === 0) {
      setShowBg(false)
      return;
    }
      setShowBg(true)
  }
  function handleOrbitEnd() {
    if (!showCarInfoTabTiming) {
      const timer = () => setTimeout(() => {
        setShowCarInfoTab(true)
      }, 2000)
      setShowCarInfoTabTiming(timer)
    } else {
      clearTimeout(showCarInfoTabTiming);
      setShowCarInfoTab(false)
      const timer = () => setTimeout(() => {
        setShowCarInfoTab(true)
      }, 2000)
      setShowCarInfoTabTiming(timer)
    }
  }

  function onCanvasCreated(state) {
    console.log('onCanvasCreated');
    state.gl.localClippingEnabled = true;
  }

  const handleOnZoom = debounce(function(distance) {
    if (distance > startDistance.current) {
      endDistance.current = startDistance.current;
    }
    if (
      endDistance.current !== 0 && (distance > endDistance.current && distance - endDistance.current > 0.5)) {
      endDistance.current = 0;
      cameraControlsRef.current.setTarget(0, 0, 0, true);
      cameraControlsRef.current.dolly(-10, true);
      setIsFocusSpolier(false);
    }
  }, 100);

  // const checkScrollDirection = debounce(function (event) {
  //   if (!cameraControlsRef.current) {
  //     return;
  //   }

  //   if (!checkScrollDirectionIsUp(event)) {
  //     // console.log('Math.abs(cameraControlsRef.current.distance)', Math.abs(cameraControlsRef.current.distance))
  //     if (Math.abs(cameraControlsRef.current.distance) > 2.5) {
  //       cameraControlsRef.current.setTarget(0, 0, 0, true);
  //       cameraControlsRef.current.dolly(-10, true);
  //     }
  //   }
  // }, 400);

  // function checkScrollDirectionIsUp(event) {
  //   return event.deltaY < 0;
  // }

  function onClickSpoiler(object) {
    cameraControlsRef.current.setLookAt( object.position.x + 2, object.position.y, object.position.z,
      (object.position.x + 2) / 2, object.position.y / 2, 0,
      true )

  }
  function onLookAtSpoiler(object) {
    cameraControlsRef.current.setTarget(
      (object.position.x + 2) / 2, object.position.y / 2, 0,
      true )
  }
  function handleSwitchSpoiler() {
    setIsShowOemSpoiler(!isShowOemSpoiler);
    setTimeout(() => {
      setIsFocusSpolier(true);
    }, 1000)
  }

  function onCameraUpdate() {
    if (cameraControlsRef.current && !initializedCamera) {
      cameraControlsRef.current.addEventListener('control', function(e) {
        if (distanceCount.current >= 6) {
          distanceCount.current = 0;
          startDistance.current = e.target.distance;
        } else {
          distanceCount.current += 1;
        }
        handleOnZoom(e.target.distance);
      })
      setInitializedCamera(true);
    }
  }

  return (
    <div ref={canvasRef} className="relative Configurator">
      <div className={'loading-overlay' + (showDelayLoading ? ' show' : '')}>
        <img src={jflogo} alt="jflogo" />
      </div>
      <Suspense fallback={<LoadingScreen />}>
        <Canvas
          gl={{
            antialias: true,
            toneMappingExposure: 0.7,
            pixelRatio: Math.min(window.devicePixelRatio, 2),
          }}
          shadows
          id="three-canvas"
          onCreated={onCanvasCreated}
        >
          <PerspectiveCamera
            ref={camRef}
            fov={isMobileAndTabletControlVertical ? 45 : 42}
            position={
              isMobileAndTabletControlVertical
              // x: , y: , z: }
                ? [-9.708949167153316, 0.8967611230370927, 6.995150145482383]
                : [-2.9255227295686326, 0.5114545823734407, 5.6584359711324534]
            }
            makeDefault
          />
          <group
            position={[
              0,
              isMobileAndTabletHorizontal ? -0.6 : -0.4,
              0
            ]}
          >
            <Car
              carId={CAR_OPTIONS_LIST[selectedCarIndex].key}
              modelFile={CAR_OPTIONS_LIST[selectedCarIndex].file}
              suspension={carSuspension}
              color={carColor}
              selectedPaint={selectedPaint}
              isShowOemSpoiler={isShowOemSpoiler}
              isFocusSpolier={isFocusSpolier}
              onClickSpoiler={onClickSpoiler}
              onLookAtSpoiler={onLookAtSpoiler}
            />
            { showBg ? <Background modelFile={BG_LIST[selectedBgIndex].bg} /> : <></> }
          </group>
          <hemisphereLight intensity={0.5} />
          {
            showBg
              ? <Environment
                ground={BG_LIST[selectedBgIndex].ground}
                files={BG_LIST[selectedBgIndex].hdri}
              />
              : <Environment
                background={false} // Whether to affect scene.background
                files={HDRI_LIST['canary_wharf']}
              />
          }
          <CameraControls
            ref={cameraControlsRef}
            minPolarAngle={1.1}
            maxPolarAngle={Math.PI / 2.1}
            minDistance={isMobileAndTabletControlVertical ? 3.2 : 2.2}
            maxDistance={isMobileAndTabletControlVertical ? 13 : 6}
            dollyToCursor={true}
            dollySpeed={0.5}
            dampingFactor={0.09}
            draggingDampingFactor={0.15}
            truckSpeed={2}
            onUpdate={onCameraUpdate}
          />
        </Canvas>
      </Suspense>
      <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2">
          <button className="px-6 py-4 bg-gray-700 text-white" onClick={handleSwitchSpoiler}>
            { isShowOemSpoiler ? 'Switch to Limo Spolier' : 'Switch to OE Spolier'}
          </button>
      </div>
      {
        showDelayLoading
          ? <></>
          : <div
            className={
              "absolute top-0 left-0 z-50 w-full transition-all duration-300 ease-[cubic-bezier(0.33,1,0.68,1)]" +
              (
                showCarList
                  ? showCarInfoTab ? ' transform translate-y-0' : ' transform -translate-y-full'
                  : showCarInfoTab ? ' transform translate-y-close-car-list' : ' transform -translate-y-full'
              )
            }
            style={{
              height: 'var(--vh)'
            }}
          >
            <div className="absolute top-0 left-0 w-full h-full bg-black opacity-60"></div>
            <ul
              className={
                "absolute top-0 left-0 w-full h-full flex pb-[64px] overflow-hidden" +
                (isMobileAndTabletHorizontal ? ' flex-row' : ' flex-col md:flex-row')
              }
            >
              {
                CAR_OPTIONS_LIST.map((car, index) =>
                  <li
                    key={car.key + 'OptionList'}
                    className={
                      "relative w-full h-full grow group overflow-hidden cursor-pointer" +
                      (selectedCarIndex === index ? ' pointer-events-none' : '')
                    }
                    onClick={() => handleSelectCar(car)}
                  >
                    <div className={
                      "absolute top-0 left-0 z-10 w-full h-full bg-black transition-all duration-400 ease-[cubic-bezier(0.33,1,0.68,1)] opacity-60 group-hover:opacity-0" +
                      (selectedCarIndex === index ? " opacity-0" : " opacity-60")
                    }>
                    </div>
                    <img
                      src={car.img}
                      alt={car.name}
                      className={
                        "w-full h-full object-cover transition-all duration-500 ease-[cubic-bezier(0.33,1,0.68,1)] group-hover:object-[40%_center]" +
                        (selectedCarIndex === index ? ' object-[40%_center]' : ' object-[10%_center]')
                      }
                    />
                    <span
                      className={
                        "absolute top-1/2 left-1/2 z-[11] -translate-x-1/2 -translate-y-1/2 text-center text-white transition-opacity duration-400 ease-[cubic-bezier(0.33,1,0.68,1)] opacity-100 group-hover:opacity-0" +
                        (CAR_OPTIONS_LIST[selectedCarIndex].key === car.key ? " hidden" : " inline")
                      }
                    >
                      { car.name }
                    </span>
                  </li>
                )
              }
            </ul>
            { document.querySelector('label[for^=rc-editable]') && createPortal(<PaintOption />, document.querySelector('label[for^=rc-editable]').parentElement) }
            <button
              className={
                "absolute left-0 bottom-0 flex items-center w-full h-[64px] px-4 z-10" +
                (showCarList ? ' justify-center' : '')
              }
              onClick={() => setShowCarList(!showCarList)}
            >
              {
                showCarList
                  ? <></>
                  : <h1 className="relative z-10 text-white md:text-lg mr-4">
                    {CAR_OPTIONS_LIST[selectedCarIndex].name}
                  </h1>
              }
              {
                showCarList
                  ? <span className="text-white">Back</span>
                  : <IconChangeCar />
              }
            </button>
          </div>
      }
    </div>
  );
}

export default Configurator;
